<script lang="tsx">
import { defineComponentCoreIcon } from '@core/app/components/core/icon/CoreIcon.vue'

export default defineComponentCoreIcon(
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.222 6C6.358 6 5.548 6.334 4.942 6.941C3.684 8.201 3.684 10.252 4.943 11.514L12.002 18.585L19.062 11.514C20.321 10.252 20.321 8.201 19.062 6.941C17.85 5.726 15.714 5.728 14.502 6.941L12.71 8.736C12.334 9.113 11.67 9.113 11.294 8.736L9.502 6.94C8.896 6.334 8.087 6 7.222 6ZM12.002 21C11.737 21 11.482 20.895 11.295 20.706L3.527 12.926C1.491 10.886 1.491 7.567 3.527 5.527C4.511 4.543 5.823 4 7.222 4C8.621 4 9.934 4.543 10.917 5.527L12.002 6.614L13.087 5.528C14.071 4.543 15.383 4 16.783 4C18.181 4 19.494 4.543 20.477 5.527C22.514 7.567 22.514 10.886 20.478 12.926L12.71 20.707C12.522 20.895 12.268 21 12.002 21Z"
            fill="currentColor"
        />
    </svg>
)

</script>

<style lang="scss" scoped>

</style>
